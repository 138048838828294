@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 50px 0;
}

.videoWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.videoItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}