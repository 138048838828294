@import '../../marketplace.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.form {
  flex-grow: 1;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

@media (--viewportLarge) {
  margin-top: 0;
  margin-bottom: 0;
}
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
  margin-top: 15px;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.time {
  width: 100px;
  margin-right: 20px !important;
}
